var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { space, display, fontSize } from 'styled-system';
import arrowHoverState from '../../assets/icons/arrow-left-hover-state.svg';
import arrow from '../../assets/icons/arrow-left.svg';
import { themeWidth, themeMaxWidth, themeHeight, themeMaxHeight, textDecoration, } from '../../theme/system-utilities';
import { Link } from '../Link';
var SeeAllLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  text-decoration: none;\n\n  &::after {\n    content: '';\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    background-image: url(", ");\n    background-position: center;\n    background-size: 22px;\n    background-repeat: no-repeat;\n    transform: rotate(180deg);\n  }\n\n  &:hover {\n    &::after {\n      background-image: url(", ");\n    }\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  text-decoration: none;\n\n  &::after {\n    content: '';\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    background-image: url(", ");\n    background-position: center;\n    background-size: 22px;\n    background-repeat: no-repeat;\n    transform: rotate(180deg);\n  }\n\n  &:hover {\n    &::after {\n      background-image: url(", ");\n    }\n  }\n"])), display, space, fontSize, textDecoration, themeWidth, themeMaxWidth, themeHeight, themeMaxHeight, arrow, arrowHoverState);
SeeAllLink.defaultProps = {
    display: 'inline-flex',
    themeWidth: 4,
    themeMaxWidth: 4,
    themeHeight: 4,
    themeMaxHeight: 4,
    pb: [4, '28px', '42px'],
};
export default SeeAllLink;
var templateObject_1;
