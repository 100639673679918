var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { borderRadius, height, width } from 'styled-system';
import { ResponsiveLazyImageMemo } from '../../common/components/LazyImage';
import ResponsiveImageBase from '../../common/components/ResponsiveImage';
import { getForwardPropOpts } from '../../common/theme/helpers/forward-props';
import { objectFit } from '../../common/theme/system-utilities';
import { SLIDES_TO_SHOW_XL } from './hooks/carousel-config';
var RESPONSIVE_IMAGE_SIZE = 252;
var LazyImage = styled(ResponsiveLazyImageMemo, getForwardPropOpts(['objectFit'], false))(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), width, height, objectFit);
LazyImage.defaultProps = {
    width: 1,
    height: '100%',
    objectFit: 'contain',
};
var StyledResponsiveImage = styled(ResponsiveImageBase)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), width, height, borderRadius);
StyledResponsiveImage.defaultProps = __assign(__assign({}, LazyImage.defaultProps), { borderRadius: 'medium' });
var ResponsiveImage = function (_a) {
    var src = _a.src, isLazy = _a.isLazy, alt = _a.alt;
    return (React.createElement(StyledResponsiveImage, { src: src, alt: alt, widths: RESPONSIVE_IMAGE_SIZE, isLazy: isLazy }));
};
var SlideImage = function (_a) {
    var imageProps = _a.imageProps, index = _a.index, alt = _a.alt;
    if (typeof imageProps === 'string') {
        var isLazy = index > SLIDES_TO_SHOW_XL;
        return React.createElement(ResponsiveImage, { src: imageProps, isLazy: isLazy, alt: alt });
    }
    if (imageProps.original) {
        return React.createElement(ResponsiveImage, { src: imageProps.original, isLazy: true, alt: alt });
    }
    return React.createElement(LazyImage, __assign({}, imageProps));
};
export default SlideImage;
var templateObject_1, templateObject_2;
