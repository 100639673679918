var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { display, space } from 'styled-system';
import { Link } from '../../common/components/Link';
import Ratio from '../../common/components/Ratio';
import { SmallHeading } from '../../common/components/Text';
import { wordBreak } from '../../common/theme/system-utilities';
import SlideImage from './SlideImage';
import { useTrackingEvents } from './hooks/tracking';
export var IMAGE_RATIO = 0.72;
var SlideContainer = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, space);
SlideContainer.defaultProps = {
    display: 'block',
};
var SlideDescription = styled(SmallHeading)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), wordBreak);
SlideDescription.defaultProps = __assign(__assign({}, SmallHeading.defaultProps), { as: 'p', mx: 0, my: 0, fontWeight: 'normal', textAlign: 'center', wordBreak: 'break-word', color: 'sys.neutral.text.default' });
var getTypography = function (withBackground) {
    return withBackground
        ? {
            lineHeight: ['lh_20', 'lh_24', null, 'lh_24'],
            fontSize: ['fs_14', 'fs_16', null, 'fs_16'],
        }
        : {
            lineHeight: ['lh_20', 'lh_24', null, '26px'],
            fontSize: ['fs_14', 'fs_16', null, 'fs_18'],
        };
};
export var VisualNavigationItem = function (_a) {
    var link = _a.link, description = _a.description, imageProps = _a.image, index = _a.index, itemsCount = _a.itemsCount, title = _a.title, withDataPrefetch = _a.withDataPrefetch, tracking = _a.tracking, withBackground = _a.withBackground, imageRatio = _a.imageRatio;
    var onImageClick = useTrackingEvents({
        linkText: description,
        listPosition: index,
        listLength: itemsCount,
        listItem: description,
        title: title,
        tracking: tracking,
    }).onImageClick;
    return (React.createElement(SlideContainer, __assign({ href: link, onClick: onImageClick, "data-testid": "visual-navigation-slide-".concat(index), mr: withBackground
            ? ['sp_16', 'sp_24', null, null]
            : ['sp_16', '18px', 'sp_20', 'sp_16'] }, (withDataPrefetch && { 'data-prefetch': true })),
        React.createElement(Ratio, { ratio: imageRatio !== null && imageRatio !== void 0 ? imageRatio : IMAGE_RATIO },
            React.createElement(SlideImage, { imageProps: imageProps, index: index, alt: description })),
        React.createElement(SlideDescription, __assign({}, getTypography(withBackground), { "data-testid": "description-slide" }), description)));
};
var templateObject_1, templateObject_2;
