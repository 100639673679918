var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { alignItems, display, height, minWidth } from 'styled-system';
import Carousel from '../../common/components/Carousel';
import SeeAllLink from '../../common/components/LazyCarousel/SeeAllLink';
import { useCarouselConfig } from './hooks/carousel-config';
import { useTrackingEvents, useViewTrackingEvent } from './hooks/tracking';
import { VisualNavigationItem } from './VisualNavigationItem';
var SLIDE_WIDTH = [132, 200, 228, 248];
var ContainerRef = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), height);
ContainerRef.defaultProps = {
    height: '1px',
};
var SlideContainer = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), minWidth, display, alignItems);
export var VisualNavigation = function (_a) {
    var items = _a.items, tracking = _a.tracking, withBackground = _a.withBackground, imageRatio = _a.imageRatio, seeAllLink = _a.seeAllLink, title = _a.title, subtitle = _a.subtitle;
    var _b = __read(useState(0), 2), activeSlide = _b[0], setActiveSlide = _b[1];
    var slidesToShow = useCarouselConfig(withBackground).slidesToShow;
    var ref = useViewTrackingEvent({
        title: title,
        items: items,
        activeSlide: activeSlide,
        slidesToShow: slidesToShow,
        tracking: tracking,
    }).ref;
    var onSeeAllLinkClick = useTrackingEvents({
        title: title,
        linkText: seeAllLink === null || seeAllLink === void 0 ? void 0 : seeAllLink.label,
        linkUrl: seeAllLink === null || seeAllLink === void 0 ? void 0 : seeAllLink.href,
        tracking: tracking,
    }).onSeeAllLinkClick;
    var handleAfterSlide = useCallback(function (currentSlide) {
        setActiveSlide(currentSlide);
    }, [activeSlide]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ContainerRef, { ref: ref }),
        React.createElement(Carousel, { afterSlide: handleAfterSlide, title: title, subtitle: subtitle, withTopControls: true },
            items.map(function (itemProps, index) { return (React.createElement(SlideContainer, { key: index, minWidth: SLIDE_WIDTH },
                React.createElement(VisualNavigationItem, __assign({}, itemProps, { key: "slide-".concat(index), index: index, itemsCount: items.length, withDataPrefetch: index - activeSlide <= slidesToShow, withBackground: withBackground, imageRatio: imageRatio }, (title && { title: title }), (tracking && { tracking: tracking }))))); }),
            (seeAllLink === null || seeAllLink === void 0 ? void 0 : seeAllLink.href) && (seeAllLink === null || seeAllLink === void 0 ? void 0 : seeAllLink.label) && (React.createElement(SlideContainer, { key: items.length, minWidth: "fit-content", display: "flex", alignItems: "center" },
                React.createElement(SeeAllLink, { "data-testid": "see-all-link", fontSize: "fs_16", lineHeight: "lh_24", pb: 0, textDecoration: "underline", href: seeAllLink.href, onClick: onSeeAllLinkClick }, seeAllLink.label))))));
};
var templateObject_1, templateObject_2;
