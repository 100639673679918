var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { VisualNavigationType } from 'corgi-types';
import { backgroundColor, borderRadius, overflow } from 'styled-system';
import ContainerWithBackground from '../../common/components/ContainerWithBackground';
import { FlexAlignItems, Grid } from '../../common/components/Grid';
import SkeletonLoader from '../../common/components/SkeletonLoader';
import { useLoadVisualNavigation } from '../../common/hooks/visual-navigation/data';
import { useLocalization } from '../../common/providers/LocaleProvider';
import Placeholder from './Placeholder';
import { VisualNavigation } from './VisualNavigation';
var VisualNavigationGrid = styled(Grid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), overflow);
VisualNavigationGrid.defaultProps = {
    py: 'sp_0',
    px: 'sp_0',
    overflow: 'hidden',
    mb: ['sp_16', 'sp_24', null, 'sp_32'],
    alignItems: FlexAlignItems.CENTER,
};
var SyledContainerWithBackground = styled(VisualNavigationGrid)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), backgroundColor, borderRadius);
SyledContainerWithBackground.defaultProps = __assign(__assign(__assign({}, VisualNavigationGrid.defaultProps), ContainerWithBackground.defaultProps), { mb: 'sp_0' });
// eslint-disable-next-line complexity
var CommonVisualNavigation = function (_a) {
    var title = _a.title, titleTranslationKey = _a.titleTranslationKey, subtitleTranslationKey = _a.subtitleTranslationKey, seeAllLink = _a.seeAllLink, forceVisibleSlidesCount = _a.forceVisibleSlidesCount, tracking = _a.tracking, withBackground = _a.withBackground, imageRatio = _a.imageRatio, props = __rest(_a, ["title", "titleTranslationKey", "subtitleTranslationKey", "seeAllLink", "forceVisibleSlidesCount", "tracking", "withBackground", "imageRatio"]);
    var t = useLocalization().t;
    var _b = useLoadVisualNavigation(props), items = _b.items, loaded = _b.loaded, error = _b.error;
    var isTitleAvailable = Boolean(title || titleTranslationKey);
    var isSubtitleAvailable = Boolean(subtitleTranslationKey);
    var visualNavigationTitle = titleTranslationKey ? t(titleTranslationKey) : title;
    if (error) {
        return null;
    }
    var VisualNavigationContainer = withBackground
        ? SyledContainerWithBackground
        : VisualNavigationGrid;
    var subtitle = !!subtitleTranslationKey ? t(subtitleTranslationKey) : '';
    return (React.createElement(SkeletonLoader, { ready: loaded, placeholder: React.createElement(Placeholder, { withBackground: withBackground, showLoadingAnimation: true, showTitle: isTitleAvailable, showSubtitle: isSubtitleAvailable, forceVisibleSlidesCount: forceVisibleSlidesCount }) }, (items === null || items === void 0 ? void 0 : items.length) > 0 ? (React.createElement(VisualNavigationContainer, { bg: withBackground ? 'unset' : 'sys.neutral.background.default', "data-testid": "visual-navigation-container" },
        React.createElement(VisualNavigation, __assign({ items: items, forceVisibleSlidesCount: forceVisibleSlidesCount, subtitle: subtitle, withBackground: withBackground, imageRatio: imageRatio }, (seeAllLink && { seeAllLink: seeAllLink }), (isTitleAvailable && { title: visualNavigationTitle }), (tracking && { tracking: tracking }))))) : null));
};
CommonVisualNavigation.defaultProps = {
    type: VisualNavigationType.Default,
    urlParams: '',
};
export default CommonVisualNavigation;
var templateObject_1, templateObject_2;
