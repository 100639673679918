var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { Viewport } from '../../../common/enums/Viewport';
import { useMatchesViewport } from '../../../common/hooks/media';
/**
 * @TODO Code refactoring needed.
 * We should consider a refactoring of this logic involving these `SLIDES_TO_SHOW_*`
 * and `SLIDE_WIDTH_*` variables. Most of this logic was ported during the carousel
 * library migration from `nuka-carousel` to `@glidejs/glide` and it's probably no longer needed.
 * Another reason for a refactoring is that this logic is no longer accurate, for example:
 * Right below, we have `SLIDES_TO_SHOW_XL` with the value 5, assuming 5 slides should be shown in the
 * Extra Large (XL) breakpoint. However, 6 and a half slides are actually shown in the component.
 */
export var SLIDES_TO_SHOW_XL = 5;
var SLIDES_TO_SHOW_L = 4;
var SLIDES_TO_SHOW_M = 3;
export var SLIDES_TO_SHOW_S = 2;
var SLIDE_WIDTH_LARGE_DESKTOP = 0.85;
var SLIDE_WIDTH_DESKTOP = 0.85;
var SLIDE_WIDTH_TABLET = 0.72;
export var SLIDE_WIDTH_MOBILE = 0.81;
var SLIDE_WIDTH_LARGE_DESKTOP_WITH_BG = 1.05;
var SLIDE_WIDTH_DESKTOP_WITH_BG = 0.84;
var SLIDE_WIDTH_TABLET_WITH_BG = 0.79;
export var SLIDE_WIDTH_MOBILE_WITH_BG = 0.92;
export var SLIDE_FALLBACK_WIDTH = [
    SLIDE_WIDTH_MOBILE / SLIDES_TO_SHOW_S,
    SLIDE_WIDTH_TABLET / SLIDES_TO_SHOW_M,
    SLIDE_WIDTH_DESKTOP / SLIDES_TO_SHOW_L,
    SLIDE_WIDTH_LARGE_DESKTOP / SLIDES_TO_SHOW_XL,
];
export var SLIDE_FALLBACK_WIDTH_WITH_BG = [
    SLIDE_WIDTH_MOBILE_WITH_BG / SLIDES_TO_SHOW_S,
    SLIDE_WIDTH_TABLET_WITH_BG / SLIDES_TO_SHOW_M,
    SLIDE_WIDTH_DESKTOP_WITH_BG / SLIDES_TO_SHOW_L,
    SLIDE_WIDTH_LARGE_DESKTOP_WITH_BG / SLIDES_TO_SHOW_XL,
];
/**
 * In order to show always, for each breakpoint, a defined number of slides, we have
 * to manually set the slideWidth, to overwrite the default behaviour (=> slider-width / slides-total-number),
 * and the slidesToShow, to define the number of slides we want to show at once.
 * WARNING: If the slideWidth that is passed to Carousel is
 * changed, please update SlideContainer width values
 */
var CAROUSEL_CONFIG = (_a = {},
    _a[Viewport.LargeDesktop] = {
        slidesToShow: SLIDES_TO_SHOW_XL,
        slideWidth: SLIDE_WIDTH_LARGE_DESKTOP,
        frameOverflow: 'hidden',
        scrollToTheLastSlide: false,
    },
    _a[Viewport.Desktop] = {
        slidesToShow: SLIDES_TO_SHOW_L,
        slideWidth: SLIDE_WIDTH_DESKTOP,
        frameOverflow: 'visible',
        scrollToTheLastSlide: false,
    },
    _a[Viewport.Tablet] = {
        slidesToShow: SLIDES_TO_SHOW_M,
        slideWidth: SLIDE_WIDTH_TABLET,
        frameOverflow: 'visible',
        scrollToTheLastSlide: false,
    },
    _a[Viewport.Mobile] = {
        slidesToShow: SLIDES_TO_SHOW_S,
        slideWidth: SLIDE_WIDTH_MOBILE,
        frameOverflow: 'visible',
        scrollToTheLastSlide: true,
    },
    _a);
var getConfig = function (_a, withBackground) {
    var _b = _a.matches, matchesTablet = _b.matchesTablet, matchesDesktop = _b.matchesDesktop, matchesLargeDesktop = _b.matchesLargeDesktop;
    switch (true) {
        case matchesLargeDesktop:
            return __assign(__assign({}, CAROUSEL_CONFIG[Viewport.LargeDesktop]), (withBackground && {
                slideWidth: SLIDE_WIDTH_LARGE_DESKTOP_WITH_BG,
            }));
        case matchesDesktop:
            return __assign(__assign({}, CAROUSEL_CONFIG[Viewport.Desktop]), (withBackground && { slideWidth: SLIDE_WIDTH_DESKTOP_WITH_BG }));
        case matchesTablet:
            return __assign(__assign({}, CAROUSEL_CONFIG[Viewport.Tablet]), (withBackground && { slideWidth: SLIDE_WIDTH_TABLET_WITH_BG }));
        default:
            return __assign(__assign({}, CAROUSEL_CONFIG[Viewport.Mobile]), (withBackground && { slideWidth: SLIDE_WIDTH_MOBILE_WITH_BG }));
    }
};
export var useCarouselConfig = function (withBackground, forceVisibleSlidesCount) {
    var matchesViewport = useMatchesViewport();
    var config = getConfig(matchesViewport, withBackground);
    if (forceVisibleSlidesCount) {
        return __assign(__assign({}, config), { slidesToShow: forceVisibleSlidesCount });
    }
    return config;
};
