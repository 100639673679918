var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useMemo } from 'react';
import { getDyTrackingGA4Params } from '../../../common/tracking/getDyTrackingGA4Params';
import { dispatchTrackingEventGA4 } from '../../../common/tracking/dispatchTrackingEventGA4';
import { useIsObserved } from '../../../common/hooks/intersection-observer';
var EventName;
(function (EventName) {
    EventName["CommonVisualNavigationView"] = "common-visual-navigation_view";
    EventName["CommonVisualNavigationClick"] = "common-visual-navigation_click";
})(EventName || (EventName = {}));
export var useTrackingEvents = function (_a) {
    var title = _a.title, linkText = _a.linkText, linkUrl = _a.linkUrl, listPosition = _a.listPosition, listLength = _a.listLength, listItem = _a.listItem, tracking = _a.tracking;
    var dyTrackingParams = getDyTrackingGA4Params(tracking);
    var onImageClick = useCallback(function () {
        dispatchTrackingEventGA4(__assign({ event: EventName.CommonVisualNavigationClick, element: 'image', title: title !== null && title !== void 0 ? title : '', list_position: listPosition, list_length: listLength, list_item: listItem }, dyTrackingParams));
    }, [title, listPosition, listLength, listItem, dyTrackingParams]);
    var onSeeAllLinkClick = useCallback(function () {
        dispatchTrackingEventGA4(__assign({ event: EventName.CommonVisualNavigationClick, element: 'link', title: title !== null && title !== void 0 ? title : '', link_text: linkText, link_url: linkUrl }, dyTrackingParams));
    }, [title, linkText, linkUrl, dyTrackingParams]);
    return { onImageClick: onImageClick, onSeeAllLinkClick: onSeeAllLinkClick };
};
export var useViewTrackingEvent = function (_a) {
    var title = _a.title, items = _a.items, activeSlide = _a.activeSlide, slidesToShow = _a.slidesToShow, tracking = _a.tracking;
    var _b = __read(useIsObserved(), 2), ref = _b[0], isObserved = _b[1];
    var shownItemsIndex = useMemo(function () { return new Set(); }, [items]);
    useEffect(function () {
        if (isObserved) {
            var newItemsToTrack_1 = [];
            items
                .slice(activeSlide, activeSlide + (slidesToShow + 1))
                .forEach(function (item, index) {
                if (!shownItemsIndex.has(items.indexOf(item))) {
                    newItemsToTrack_1.push({
                        list_position: index + activeSlide,
                        list_item: item.description,
                    });
                    shownItemsIndex.add(index + activeSlide);
                }
            });
            if (newItemsToTrack_1.length) {
                dispatchTrackingEventGA4(__assign({ event: EventName.CommonVisualNavigationView, element: 'image', title: title !== null && title !== void 0 ? title : '', list_length: items.length, items: newItemsToTrack_1 }, getDyTrackingGA4Params(tracking)));
            }
        }
    }, [activeSlide, slidesToShow, items, tracking, isObserved]);
    return { ref: ref };
};
