var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { opacity, overflow } from 'styled-system';
var RatioOuter = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  width: 100%;\n  height: 0;\n  padding-bottom: calc(100% * ", ");\n  position: relative;\n"], ["\n  ", ";\n  ", ";\n  width: 100%;\n  height: 0;\n  padding-bottom: calc(100% * ", ");\n  position: relative;\n"])), opacity, overflow, function (props) { return props.ratio; });
RatioOuter.defaultProps = {
    overflow: 'hidden',
    opacity: 1,
};
var RatioInner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n"])));
var Ratio = function (_a) {
    var ratio = _a.ratio, ratioOverflow = _a.ratioOverflow, ratioOpacity = _a.ratioOpacity, props = __rest(_a, ["ratio", "ratioOverflow", "ratioOpacity"]);
    return (React.createElement(RatioOuter, { "data-testid": "ratio-outer", ratio: ratio, overflow: ratioOverflow, opacity: ratioOpacity },
        React.createElement(RatioInner, __assign({}, props))));
};
Ratio.defaultProps = {
    ratio: 1,
};
export default Ratio;
var templateObject_1, templateObject_2;
