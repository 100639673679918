export var Viewport;
(function (Viewport) {
    Viewport["Mobile"] = "mobile";
    Viewport["Tablet"] = "tablet";
    Viewport["Desktop"] = "desktop";
    Viewport["LargeDesktop"] = "large_desktop";
    Viewport["MobileTablet"] = "mobile_tablet";
    Viewport["TabletDesktop"] = "tablet_desktop";
    Viewport["All"] = "all";
})(Viewport || (Viewport = {}));
