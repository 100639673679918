var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { space, backgroundColor, borderRadius, position } from 'styled-system';
var ContainerWithBackground = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, backgroundColor, borderRadius, position);
ContainerWithBackground.defaultProps = {
    backgroundColor: 'sys.neutral.background.default',
    px: ['sp_16', 'sp_24', 'sp_16', 'sp_24'],
    /**
     * Setting vertical padding values separately to allow
     * overriding them from outside
     */
    py: 'sp_24',
    pt: 'sp_24',
    pb: 'sp_24',
    borderRadius: 'medium',
};
export default ContainerWithBackground;
var templateObject_1;
