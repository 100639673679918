var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import styled from '@emotion/styled';
import { height, space, maxWidth, overflow, background } from 'styled-system';
import ContainerWithBackground from '../../common/components/ContainerWithBackground';
import { Flex } from '../../common/components/Flex';
import RectShape from '../../common/components/RectShape';
import { maxWidth as globalThemeMaxWidth } from '../../common/theme/theme';
import { IMAGE_RATIO } from './VisualNavigationItem';
/**
 * There is no need to use the amount of slides loaded from GQL as this data is now loaded on the
 * client side. Therefore, I chose the fixed value of 8 because it is greater than the largest
 * number of slides visible at any breakpoint. So the placeholder will always be loaded as if
 * there were the amount needed to fill the entire slider.
 */
var FIXED_SLIDE_COUNT = 8;
/**
 * These quotients are the result of the division between: the whole component width and the
 * width of the slides generated by the Glide library in the CommonVisualNavigation component.
 * Values: ['small', 'medium', 'large', 'extra-large']
 */
var SLIDE_WIDTH_QUOTIENT = [2.46, 4.16, 4.7, 5.88];
var ContainerBase = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), maxWidth, background, overflow);
ContainerBase.defaultProps = {
    width: 1,
    maxWidth: globalThemeMaxWidth,
    mx: 'auto',
    mb: ['sp_24', null, null, 'sp_32'],
    flexDirection: 'column',
    overflow: 'hidden',
};
var StyledContainerWithBackground = styled(ContainerWithBackground)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), overflow);
StyledContainerWithBackground.defaultProps = __assign(__assign(__assign({}, ContainerBase.defaultProps), ContainerWithBackground.defaultProps), { overflow: 'hidden' });
var Title = styled(RectShape)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), height, space);
Title.defaultProps = __assign(__assign({}, RectShape.defaultProps), { width: ['85px', '95px', null, '105px'], height: ['18px', '20px', null, '22px'] });
var Subtitle = styled(RectShape)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), height, space);
Subtitle.defaultProps = __assign(__assign({}, RectShape.defaultProps), { width: ['185px', '195px', null, '205px'], height: ['18px', '20px', null, '22px'], mt: ['sp_8', null, 'sp_12'], mb: ['sp_12', 'sp_20', 'sp_24'] });
var SlidesContainer = styled(Flex)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var Slide = styled(Flex)(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
Slide.defaultProps = {
    pr: ['sp_16', '18px', 'sp_20', 'sp_16'],
    alignItems: 'center',
    flexDirection: 'column',
};
var CategoryImage = styled(RectShape)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), height, space);
CategoryImage.defaultProps = __assign(__assign({}, RectShape.defaultProps), { pb: "calc(100% * ".concat(IMAGE_RATIO, ")") });
var CategoryName = styled(RectShape)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), height, space);
CategoryName.defaultProps = __assign(__assign({}, RectShape.defaultProps), { height: [9, 22, null, 24], mt: 'sp_2' });
var getContainerWidth = function (slidesCount) {
    if (slidesCount) {
        return '100%';
    }
    return SLIDE_WIDTH_QUOTIENT.map(function (quotient) { return "calc((100% / ".concat(quotient, ") * ").concat(FIXED_SLIDE_COUNT, ")"); });
};
var getSliceWidth = function (slidesCount) {
    if (slidesCount) {
        return "calc(100% / ".concat(slidesCount, ")");
    }
    return SLIDE_WIDTH_QUOTIENT.map(function (quotient) { return "calc(100% / ".concat(quotient, ")"); });
};
var Placeholder = function (_a) {
    var _b = _a.showTitle, showTitle = _b === void 0 ? false : _b, _c = _a.showSubtitle, showSubtitle = _c === void 0 ? false : _c, showLoadingAnimation = _a.showLoadingAnimation, forceVisibleSlidesCount = _a.forceVisibleSlidesCount, withBackground = _a.withBackground;
    var Container = withBackground
        ? StyledContainerWithBackground
        : ContainerBase;
    return (React.createElement(Container, { "data-testid": "custom-placeholder-container", className: showLoadingAnimation && 'show-loading-animation' },
        showTitle && (React.createElement(Title, { mb: showSubtitle ? 'sp_0' : ['sp_12', 'sp_20', 'sp_24'] })),
        showSubtitle && React.createElement(Subtitle, null),
        React.createElement(SlidesContainer, { width: getContainerWidth(forceVisibleSlidesCount) }, __spreadArray([], __read(Array(forceVisibleSlidesCount || FIXED_SLIDE_COUNT)), false).map(function (_, index) { return (React.createElement(Slide, { key: index, width: getSliceWidth(forceVisibleSlidesCount) },
            React.createElement(CategoryImage, null),
            React.createElement(CategoryName, null))); }))));
};
export default Placeholder;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
